
import { objectDifference } from "@/helpers";
import {
  payPlanChangeRequestMapActions,
  payPlanChangeRequestMapGetters,
  payPlanChangeRequestMapMutations,
  payPlanChangeRequestMapState
} from "@/store/modules/payplanchangerequest";
import * as types from "@/store/mutation-types";
import { IAddress } from "@/types";
import { atlasfileMapActions } from "@/store/modules/atlasfile";
import { cloneDeep } from "lodash";
import { omit } from "lodash";
import { propertyChangeRequestMapState } from "@/store/modules/propertychangerequest";
import insuredSchemas from "@/forms/shared/changeRequestInsuredSchema";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import VueWithMixins from "@/helpers/mixins";
import ChangeRequestMixin from "../../ChangeRequests/ChangeRequestMixin";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

interface IAgencyAddress {
  agencyCity: string;
  agencyZipCode: string;
  agencyState: string;
  successMessage: string;
  errorMessage: string;
  activeNames: any[];
  error: string;
  agentCertifyCheckBox: boolean;
  uploadedFile: string[];
}

interface IPayPlanChangeRequestMutationData {
  fieldKey: string;
  value: string;
}
export default VueWithMixins(ChangeRequestMixin).extend({
  name: "EditPayPlanChangeRequest",
  mixins: [ActivitiesMixin],
  components: {
    PolicyInfo: () => import("@/components/User/PolicyInfo.vue"),
    ChangeRequestFileUpload: () =>
      import("@/components/AttachmentUploader/ChangeRequestFileUpload.vue"),
    ChangeRequestInsured: () =>
      import("@/components/User/ChangeRequestInsured.vue"),
    CustomAlert
  },
  props: {
    payPlanChangeRequestId: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      successMessage: "",
      activeNames: ["1", "2"],
      error: "",
      agentCertifyCheckBox: false,
      billToRadioButton: "",
      paymentPlanRadioButton: "",
      uploadedFile: [],
      insuredSchemas,
      showActivities: false,
      selectedTabKey: "edit"
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.billToRadioButton = this.editing?.data.payPlan.billTo
        ? this.editing?.data.payPlan.billTo
        : "";
      this.paymentPlanRadioButton = this.editing?.data.payPlan.payPlanOptions
        ? this.editing?.data.payPlan.payPlanOptions
        : "";
    });
  },
  destroyed() {
    this.setState({ key: "editing", value: null });
  },
  methods: {
    ...payPlanChangeRequestMapActions([
      "deletePayPlanChangeRequest",
      "updatePayPlanChangeRequest"
    ]),
    ...atlasfileMapActions(["createAtlasfile"]),
    ...payPlanChangeRequestMapMutations(["SET_EDIT"]),
    ...payPlanChangeRequestMapMutations({
      editField: types.SET_EDIT_FIELD,
      setState: types.SET_STATE
    }),
    goBackToList() {
      this.$router.push(`/payPlanchangerequests`).catch(() => {});
    },
    async deleteCallback() {
      try {
        this.$modal.show("payPlanChangeRequestSingleDeleteModal");
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async deleteOnePayPlanChangeRequest(): Promise<void> {
      await this.deletePayPlanChangeRequest(this.payPlanChangeRequestId);
      this.$modal.hide("payPlanChangeRequestSingleDeleteModal");
      this.$router.push("/payPlanchangerequests").catch(() => {});
      this.$appNotifySuccess("Pay Plan Change Request Deleted.");
    },
    attachmentUploadHandler(data: any) {
      this.uploadedFile = data;
      this.doUpdateFile();
    },
    onFileUploadError(err: string) {
      this.error = err;
    },
    async handlePayPlanChangeRequestUpdate(
      updatedPayPlanChangeRequestData: IPayPlanChangeRequestMutationData,
      keyPrefix: string = ""
    ) {
      let { fieldKey, value } = updatedPayPlanChangeRequestData;
      keyPrefix = keyPrefix ? `${keyPrefix}.` : keyPrefix;
      let mutationKey = `${keyPrefix}${fieldKey}`;
      const key = mutationKey;

      this.editField({
        key: key as any,
        value: value
      });
    },
    async handleBillToRequestUpdate(value: string) {
      await this.handlePayPlanChangeRequestUpdate({
        fieldKey: "data.payPlan.billTo",
        value
      });

      if (value == "mortgageeBilled") {
        this.paymentPlanRadioButton = "annual";

        this.handlePayPlanChangeRequestUpdate({
          fieldKey: "data.payPlan.payPlanOptions",
          value: this.paymentPlanRadioButton
        });
      }
    },
    mapFileData(response: any) {
      if (response.length) {
        let cloneEditing = cloneDeep(this.editing?.attachments);
        let newEditing: any = cloneEditing?.concat(response);
        let mapAttachments = newEditing.map((file: any) => file.id);
        this.editField({ key: "attachments", value: mapAttachments });
      } else {
        let newEditing: any = cloneDeep(this.editing?.attachments);
        newEditing.push(response);
        let mapAttachments = newEditing.map((file: any) => file.id);
        this.editField({ key: "attachments", value: mapAttachments });
      }
    },
    async sendData() {
      await this.updatePayPlanChangeRequest({
        id: this.payPlanChangeRequestId,
        update: this.updatedFields
      });
    },
    async Submit() {
      await this.updatePayPlanChangeRequest({
        id: this.payPlanChangeRequestId,
        update: {
          status: "Submitted",
          submittedOn: new Date(),
          ...this.updatedFields
        }
      });
      this.goBackToList();
      this.$appNotifySuccess("PayPlan Change Request submitted");
    },
    attachmentData(data: any) {
      this.uploadedFile = data;
    },
    createFormData(files: File[]) {
      const formData = new FormData();
      files.forEach((file: File) => formData.append(file.name, file));
      formData.append("type", "attachment");
      formData.append("isPublic", "false");
      return formData;
    },
    update() {
      this.setPayPlanRequestAdditionalData("update");
      this.doUpdate();
    },
    async doUpdate(): Promise<void> {
      if (!this.objectContainsData(this.updatedFields)) {
        return;
      }
      const status = this.editing?.status;
      if (status !== "Not Submitted") {
        return;
      }
      try {
        if (!this.uploadedFile.length) {
          return await this.sendData();
        }
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.$emit("scrollTop");
      }
    },
    async doUpdateFile(): Promise<void> {
      const status = this.editing?.status;
      if (status !== "Not Submitted") {
        return;
      }
      try {
        this.loading = true;
        let response = await this.createAtlasfile(
          this.createFormData(this.uploadedFile as any)
        );
        this.mapFileData(response);
        await this.sendData();
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.$emit("scrollTop");
      }
    },
    changeAddressProperties(agencyAddress: IAgencyAddress): IAddress {
      let newAgencyAddressProperties: IAddress = {
        unitNumber: undefined,
        streetName: "",
        houseNumber: 0,
        streetDirection: "",
        streetType: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        line2: "",
        county: ""
      };
      newAgencyAddressProperties.city = agencyAddress.agencyCity;
      newAgencyAddressProperties.zipCode = agencyAddress.agencyZipCode;
      newAgencyAddressProperties.state = agencyAddress.agencyState;

      return newAgencyAddressProperties;
    },
    objectContainsData(data: {}): boolean {
      return Object.keys(data).length > 0;
    },
    setPayPlanRequestAdditionalData(option: string) {
      const submittedOn = new Date();
      let status = "Not Submitted";
      if (option == "Submit") {
        this.editField({ key: "submittedOn", value: submittedOn });
        status = "Submitted";
      }
      this.editField({ key: "status", value: status });
    },
    onToolbarItemSelected(action: string): void {
      switch (action) {
        case "save":
          this.update();
          break;
        case "print":
          this.printCallback(
            this.editing,
            "payplanchangerequest/printPayPlanChangeRequest"
          );
          break;
        case "delete":
          this.deleteCallback();
          break;
        case "submit":
          this.Submit();
          break;
        case "cancel":
          this.goBackToList();
          break;
      }
    }
  },
  computed: {
    ...payPlanChangeRequestMapState(["makingApiRequest", "editing"]),
    ...payPlanChangeRequestMapGetters(["getPayPlanChangeRequestById"]),
    ...propertyChangeRequestMapState({
      loadingInsuredInfo: "makingApiRequest"
    }),
    original(): any {
      const res = this.getPayPlanChangeRequestById(this.payPlanChangeRequestId);
      return res;
    },
    updatedFields(): any {
      const toIgnore = ["data.agentInfo"];
      return omit(
        this.editing && this.original
          ? objectDifference(this.editing, this.original)
          : {},
        toIgnore
      );
    },
    primaryToolbar(): any {
      if (this.editing && this.editing.status === "Not Submitted") {
        return {
          text: "Submit",
          key: "submit",
          loading: this.makingApiRequest,
          disabled:
            this.checkForEmptyRequiredFields ||
            this.policyIsLapsedAndHasNoAttachments(this.editing)
        };
      }
      return undefined;
    },
    secondaryToolbar(): any {
      if (this.editing) {
        const defaultActions = {
          text: "Action",
          key: "actions",
          subItems: [
            {
              title: "Print",
              command: "print",
              loading: this.makingApiRequest
            },
            {
              title: "Delete",
              command: "delete"
            }
          ]
        };
        if (this.editing.status === "Submitted") {
          return defaultActions;
        } else {
          defaultActions.subItems.push({
            title: "Cancel",
            command: "cancel",
            loading: this.makingApiRequest
          });
          return defaultActions;
        }
      }
      return undefined;
    },
    checkForEmptyRequiredFields() {
      if (this.editing && this.editing.data && this.editing.data.payPlan) {
        if (
          this.editing.data.payPlan.billTo === undefined ||
          this.editing.data.payPlan.billTo === "" ||
          this.editing.data.payPlan.payPlanOptions === undefined ||
          this.editing.data.payPlan.payPlanOptions === ""
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    queryOverride(): any {
      return {
        "data.payPlanChangeRequestId": this.editing?._id,
        activityType: "PayPlanChangeRequestActivity"
      };
    }
  },
  watch: {
    updatedFields: {
      handler(fields) {
        if (Object.keys(fields).length && !fields.attachments) {
          this.$nextTick().then(() => {
            this.update();
          });
        }
      },
      deep: true
    }
  }
});
